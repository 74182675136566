.hamburger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $hamburger-padding-y $hamburger-padding-x;
  cursor: pointer;
  height: $hamburger-size;
  width: $hamburger-size;

  transition: background-color $hamburger-hover-transition-duration $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  border-radius: $border-radius;
  margin: 0;
  overflow: visible;
  outline: 0;

  &-sm {
    .hamburger-inner {
      transform: scale(.8);
    }
  }

  &:hover,
  &:focus,
  &.focus {
    background-color: rgba($white, .05);
    outline: 0;
  }
  &:active {
    background-color: rgba($black, .05);
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-width;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.hamburger-light {
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: $dark;
  }
}

/*
 * Arrow
 */
.hamburger-arrow.active,
.hamburger-arrow[aria-expanded="true"] {
  .hamburger-inner {
    &::before {
      transform: translate3d($hamburger-layer-width * -0.2, 1px, 0) rotate(-45deg) scale(0.7, 1);
    }

    &::after {
      transform: translate3d($hamburger-layer-width * -0.2, -1px, 0) rotate(45deg) scale(0.7, 1);
    }
  }
}

/*
 * Squeeze
 */
.hamburger-squeeze {
  .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }

    &::after {
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.active,
  &[aria-expanded="true"] {
    .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}
