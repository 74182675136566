.alert {
  border-width: 3px 0 0 0;
  box-shadow: 0 0 0 1px rgba($black, .05), 0 1px 3px 0 rgba($black, .15);
  @include border-top-radius(0);

  @include media-breakpoint-up(md) {
    font-size: $font-size-sm;
  }

  &.has-icon {
    padding-left: 4rem;
    min-height: 4rem;
  }

  .card > & {
    border-radius: 0;
  }
}

.alert-icon {
  position: absolute;
  left: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  text-align: center;
  border-radius: 2rem;

  .oi,
  .fa {
    font-size: 1.25rem;
  }
}

// Alternate styles

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    .alert-icon {
      color: color-yiq(theme-color-level($color, $alert-color-level));
      background-color: theme-color-level($color, $alert-color-level);
    }
  }
}

.alert-secondary {
  color: $body-color;
  background-color: $gray-100;
  border-color: $text-muted;

  .alert-icon {
    color: $text-muted;
    background-color: $gray-200;
  }

  hr {
    border-top-color: $border-color;
  }

  .alert-link {
    color: $link-color;
  }
}

.alert-light {
  border-color: $gray-500;
}
