.page-link {
  font-weight: 600;
  border-radius: 3rem;

  &:focus {
    color: $pagination-active-color;
  }

  .page-item:first-child &,
  .pagination-sm .page-item:first-child &,
  .pagination-lg .page-item:first-child & {
    border-top-left-radius: 3rem;
  }

  .page-item:last-child &,
  .pagination-sm .page-item:last-child &,
  .pagination-lg .page-item:last-child & {
    border-top-right-radius: 3rem;
  }
}
