.table {
  thead {
    th, td {
      border-top-width: 0;
      border-bottom-width: 1px;
    }
  }

  &.table-bordered {
    thead {
      th, td {
        border-top-width: 1px;
      }
    }
  }

  .card > &,
  .card > .table-responsive > & {
    margin-bottom: 0;

    &.table-bordered {
      thead {
        th, td {
          border-top-width: 0;
          border-bottom-width: 0;
        }
      }
    }

    td, th {
      &:first-child {
        padding-left: $card-spacer-x;
        border-left: 0;
      }
      &:last-child {
        padding-right: $card-spacer-x;
        border-right: 0;
      }
    }
  }

  .card > &:last-child,
  .card > .table-responsive:last-child > & {
    &.table-bordered tr:last-child {
      td, th {
        border-bottom-width: 0;
      }
    }
  }

  > thead tr:first-child,
  > tbody:first-child tr:first-child {
    > th,
    > td {
      &:first-child {
        border-top-left-radius: $border-radius;
      }
      &:last-child {
        border-top-right-radius: $border-radius;
      }
    }
  }

  > tfoot tr:last-child,
  > tbody:last-child tr:last-child {
    > th,
    > td {
      &:first-child {
        border-bottom-left-radius: $border-radius;
      }
      &:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}

.table-active {
  &,
  > th,
  > td {
    color: color-yiq($table-active-bg)
  }
}

.row-details {
  &.collapsing {
    display: none;
    transition: none;
  }
}

.card-fluid {
  > .table-responsive > .table,
  > .table {
    @include media-breakpoint-down(xs) {
      > thead tr:first-child,
      > tbody:first-child tr:first-child {
        > th,
        > td {
          &:first-child {
            border-top-left-radius: 0;
          }
          &:last-child {
            border-top-right-radius: 0;
          }
        }
      }

      > tfoot tr:last-child,
      > tbody:last-child tr:last-child {
        > th,
        > td {
          &:first-child {
            border-bottom-left-radius: 0;
          }
          &:last-child {
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
}

// Fixed Layout

.table-fixed {
  table-layout: fixed;
  min-width: 400px;

  tr > th,
  tr > td {
    overflow: hidden;
  }
}

// inline action wrapper

.thead-dd {
  display: inline-block;
  color: $text-muted;

  > .custom-control {
    position: absolute;
    top: .2em;
    display: inline-block;
  }

  .thead-btn {
    padding-left: 1.5rem;
  }
}

.col-checker {
  width: 2rem;
}

.thead-btn {
  display: inline-block;
  margin-left: -.375rem;
  padding: .125rem .375rem;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background: $custom-select-bg;
  border: 1px solid $input-border-color;
  box-shadow: 0 1px 0 0 rgba($black, .05);
  border-radius: $border-radius;

  .custom-control {
    display: inline-block;
    padding-left: 1rem;
  }
}
